import React from "react";

export function Facebook({ className }) {
  return (
    <svg
      className={className}
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4678 10.6875L11.001 7.24895H7.6669V5.01756C7.6669 4.07684 8.13265 3.15986 9.6259 3.15986H11.1416V0.232305C11.1416 0.232305 9.76615 0 8.45102 0C5.70527 0 3.91052 1.64691 3.91052 4.62828V7.24895H0.858398V10.6875H3.91052V19H7.6669V10.6875H10.4678Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Twitter({ className }) {
  return (
    <svg
      className={className}
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1498 3.51837C16.1612 3.67277 16.1612 3.82721 16.1612 3.98162C16.1612 8.69115 12.4493 14.1176 5.66499 14.1176C3.57488 14.1176 1.63326 13.5331 0 12.5184C0.296966 12.5515 0.582471 12.5625 0.890863 12.5625C2.61546 12.5625 4.20305 12 5.47083 11.0405C3.84899 11.0074 2.48985 9.98163 2.02156 8.56985C2.25001 8.60292 2.47842 8.62498 2.71829 8.62498C3.0495 8.62498 3.38074 8.58085 3.6891 8.50368C1.99875 8.17277 0.730936 6.73896 0.730936 5.00735V4.96325C1.22204 5.22795 1.79315 5.39339 2.39844 5.41542C1.40478 4.77571 0.753788 3.68381 0.753788 2.4485C0.753788 1.78675 0.936496 1.18013 1.25631 0.650719C3.07232 2.81248 5.80203 4.22422 8.86293 4.37866C8.80583 4.11395 8.77155 3.83825 8.77155 3.5625C8.77155 1.59925 10.4162 0 12.4607 0C13.5228 0 14.4822 0.430146 15.1561 1.125C15.9898 0.970595 16.7893 0.672786 17.4975 0.264708C17.2233 1.09193 16.6409 1.78679 15.8756 2.22793C16.618 2.15076 17.3376 1.95219 18 1.67648C17.4975 2.38234 16.8693 3.01099 16.1498 3.51837Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Instagram({ className }) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00332 3.95702C5.73189 3.95702 3.89975 5.76053 3.89975 7.99647C3.89975 10.2324 5.73189 12.0359 8.00332 12.0359C10.2748 12.0359 12.1069 10.2324 12.1069 7.99647C12.1069 5.76053 10.2748 3.95702 8.00332 3.95702ZM8.00332 10.6226C6.53546 10.6226 5.33546 9.44491 5.33546 7.99647C5.33546 6.54803 6.53189 5.3703 8.00332 5.3703C9.47475 5.3703 10.6712 6.54803 10.6712 7.99647C10.6712 9.44491 9.47118 10.6226 8.00332 10.6226ZM13.2319 3.79178C13.2319 4.31561 12.8033 4.73397 12.2748 4.73397C11.7426 4.73397 11.3176 4.3121 11.3176 3.79178C11.3176 3.27147 11.7462 2.8496 12.2748 2.8496C12.8033 2.8496 13.2319 3.27147 13.2319 3.79178ZM15.9498 4.74803C15.889 3.48592 15.5962 2.36796 14.6569 1.44686C13.7212 0.525769 12.5855 0.237488 11.3033 0.174207C9.98189 0.100378 6.02118 0.100378 4.69975 0.174207C3.42118 0.233972 2.28546 0.522253 1.34618 1.44335C0.406892 2.36444 0.117606 3.48241 0.0533203 4.74452C-0.0216797 6.0453 -0.0216797 9.94413 0.0533203 11.2449C0.114035 12.507 0.406892 13.625 1.34618 14.5461C2.28546 15.4672 3.41761 15.7555 4.69975 15.8187C6.02118 15.8926 9.98189 15.8926 11.3033 15.8187C12.5855 15.759 13.7212 15.4707 14.6569 14.5461C15.5926 13.625 15.8855 12.507 15.9498 11.2449C16.0248 9.94413 16.0248 6.04882 15.9498 4.74803ZM14.2426 12.6406C13.964 13.3297 13.4248 13.8605 12.7212 14.1383C11.6676 14.5496 9.16761 14.4547 8.00332 14.4547C6.83904 14.4547 4.33546 14.5461 3.28546 14.1383C2.58546 13.864 2.04618 13.3332 1.76403 12.6406C1.34618 11.6035 1.44261 9.14256 1.44261 7.99647C1.44261 6.85038 1.34975 4.38592 1.76403 3.35233C2.04261 2.66327 2.58189 2.13241 3.28546 1.85467C4.33904 1.44335 6.83904 1.53827 8.00332 1.53827C9.16761 1.53827 11.6712 1.44686 12.7212 1.85467C13.4212 2.12889 13.9605 2.65975 14.2426 3.35233C14.6605 4.38944 14.564 6.85038 14.564 7.99647C14.564 9.14256 14.6605 11.607 14.2426 12.6406Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Tiktok({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2859 3333"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      width="20"
      height="20"
    >
      <path d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z" />
    </svg>
  );
}

const components = {
  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  tiktok: Tiktok,
};
const colors = {
  facebook: "var(--green)",
  instagram: "var(--green)",
  twitter: "var(--green)",
  tiktok: "var(--green)",
};

export function Icon({ platform = "facebook" }) {
  const Comp = components[platform];
  return (
    <div
      style={{ color: colors[platform] }}
      className="social-icon flex relative justify-center items-center w-10 h-10 "
    >
      <div
        className="absolute inset-0 rounded-full border"
        style={{ borderColor: colors[platform] }}
      />
      <Comp className="relative" />
    </div>
  );
}
