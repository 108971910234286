import { Logo } from "components/Logo";
import { Icon } from "components/Social";
import { Link } from "gatsby";
import React from "react";

export function Footer({ className = "" }) {
  const year = new Date().getFullYear().toString();

  return (
    <div className={`site-footer ${className}`}>
      <div className="pt-16 pb-8" style={{ background: "#fff" }}>
        <div className="container mx-auto md:flex md:justify-between md:items-end">
          <div>
            <Logo className="block w-20 md:w-24 mx-auto h-auto" />
          </div>
          <div className="my-8 md:my-0">
            <div className="flex gap-6 justify-center social">
              <a
                target="_blank"
                href="https://www.instagram.com/fresh2deathuk/"
              >
                <Icon platform="instagram" />
              </a>
              <a target="_blank" href="https://www.facebook.com/fresh2deathuk">
                <Icon platform="facebook" />
              </a>
              <a target="_blank" href="https://tiktok.com/@fresh2deathuk">
                <Icon platform="tiktok" />
              </a>
            </div>
          </div>
        </div>
        <div className="container mx-auto flex items-center flex-col-reverse md:flex-row md:justify-between md:items-end md:mt-12">
          <div>
            <p className="text-xs mt-8 md:mt-0">&copy; Fresh2Death {year}</p>
          </div>
          <div>
            <div className="site-footer-links relative text-xs">
              {/* <Link to="/privacy-policy">PRIVACY POLICY</Link>
            <Link to="/terms-and-conditions">TERMS &amp; CONDITIONS</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
