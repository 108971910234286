import { Footer } from "components/Footer";
import React from "react";
import { motion } from "framer-motion";
import { Header } from "components/Header";

export function Page({ children, overlayHeader = false, hideFooter = false }) {
  return (
    <div className="page flex flex-col min-h-screen">
      <Header overlay={overlayHeader} />
      <div className="flex-1">
        <motion.div
          layout
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.5 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.2 },
          }}
          style={{ position: "relative" }}
        >
          {children}
        </motion.div>
      </div>
      {!hideFooter && <Footer className="mt-40" />}
    </div>
  );
}
